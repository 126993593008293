import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaCar, FaFolderOpen, FaCircle, FaRegSquare, FaCogs, FaFileAlt } from 'react-icons/fa';
import Header from './components/Header';
import './App.css'; // Importando o CSS

const CACHE_EXPIRATION_TIME = 24 * 60 * 60 * 1; // 1 dia em milissegundos

const FileItem = ({ file }) => (
  <li>
    <a href={file.webViewLink} target="_blank" rel="noopener noreferrer">
      <FaFileAlt className="file-icon" />
      {file.name.substring(0, file.name.lastIndexOf('.')) || file.name}
    </a>
  </li>
);

const FolderItem = ({ file, onClick, icon }) => (
  <li onClick={() => onClick(file.id, file.name)} style={{ cursor: 'pointer' }}>
    {icon}
    {file.name}
  </li>
);

const LoadingSpinner = () => (
  <div style={{  padding: '20px' }}>
    <div className="spinner"></div>
    <p>Carregando...</p>
  </div>
);

const SearchBar = ({ searchTerm, setSearchTerm, handleSearch }) => (
  <form onSubmit={handleSearch} style={{ marginBottom: '20px' }}>
    <input
      type="text"
      placeholder="Pesquisar..."
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      style={{ padding: '5px', width: '80%' }}
    />
    <button type="submit" style={{ marginLeft: '10px' }}>Pesquisar</button>
  </form>
);

const normalizeString = (str) => {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '') // Remove diacríticos (acentos)
    .replace(/ç/g, 'c')              // Substitui 'ç' por 'c'
    .replace(/ñ/g, 'n')              // Substitui 'ñ' por 'n')
    .replace(/[^\w\s]/gi, '');       // Remove outros caracteres especiais
};

const App = () => {
  const rootFolderId = '1uevac4WMb8PiupRtPzFa_4Gu6rRcrXlP'; // Pasta raiz
  const apiKey = process.env.REACT_APP_GOOGLE_DRIVE_API_KEY;

  const [rootFolders, setRootFolders] = useState([]);
  const [subFolders, setSubFolders] = useState([]);
  const [systemFolders, setSystemFolders] = useState([]);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentFolderId, setCurrentFolderId] = useState(rootFolderId);
  const [currentFolderName, setCurrentFolderName] = useState('MANUAIS');

  const getCache = (folderId) => {
    const cachedData = localStorage.getItem(folderId);
    if (cachedData) {
      const { data, timestamp } = JSON.parse(cachedData);
      if (Date.now() - timestamp < CACHE_EXPIRATION_TIME) {
        return data;
      } else {
        localStorage.removeItem(folderId); // Expirado, remove o cache
      }
    }
    return null;
  };

  const setCache = (folderId, data) => {
    const cacheEntry = {
      data,
      timestamp: Date.now(),
    };
    localStorage.setItem(folderId, JSON.stringify(cacheEntry));
  };

  const fetchFilesFromGoogleDrive = async (folderId) => {
    let query = `'${folderId}' in parents and trashed=false`;

    const params = {
      q: query,
      fields: 'files(id, name, mimeType, webViewLink, parents)',
      key: apiKey,
      pageSize: 1000,
    };

    const response = await axios.get(`https://www.googleapis.com/drive/v3/files`, { params });
    return response.data.files.sort((a, b) => a.name.localeCompare(b.name));
  };

  const fetchFiles = async (folderId, setState, searchTerm = '') => {
    setLoading(true);
    setError(null);

    try {
      const cachedFiles = getCache(folderId);
      if (cachedFiles && !searchTerm) {
        setState(cachedFiles);
        setLoading(false);
        return;
      }

      const files = await fetchFilesFromGoogleDrive(folderId);
      const normalizedTerm = normalizeString(searchTerm.toLowerCase());

      const filteredFiles = files.filter(file => {
        const normalizedFileName = normalizeString(file.name.toLowerCase());
        return normalizedFileName.includes(normalizedTerm);
      });

      setState(filteredFiles);
      if (!searchTerm) {
        setCache(folderId, filteredFiles);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFiles(rootFolderId, setRootFolders);
  }, [apiKey]);

  const handleRootFolderClick = (folderId, folderName) => {
    fetchFiles(folderId, setSubFolders);
    setSystemFolders([]);
    setFiles([]);
    setSearchTerm('');
    setCurrentFolderId(folderId);
    setCurrentFolderName(folderName);
  };

  const handleSubFolderClick = (folderId, folderName) => {
    fetchFiles(folderId, setSystemFolders);
    setFiles([]);
    setSearchTerm('');
    setCurrentFolderId(folderId);
    setCurrentFolderName(folderName);
  };

  const handleSystemFolderClick = (folderId, folderName) => {
    fetchFiles(folderId, setFiles);
    setSearchTerm('');
    setCurrentFolderId(folderId);
    setCurrentFolderName(folderName);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    fetchFiles(currentFolderId, setFiles, searchTerm);
  };

  const shouldShowSearchInput = files.length > 10;

  return (
    <div className="containerFull">
    <Header />

    <div className="container">

      <div className="column">
        <h3>MONTADORAS</h3>
        <ul>
          {rootFolders.map(folder => (
            <FolderItem key={folder.id} file={folder} onClick={handleRootFolderClick} icon={<FaRegSquare className="montadora-icon" />} />
          ))}
        </ul>
      </div>

      <div className="column">
        <h3>MODELOS</h3>
        <ul>
          {subFolders.map(folder => (
            <FolderItem key={folder.id} file={folder} onClick={handleSubFolderClick} icon={<FaCar className="modelo-icon" />} />
          ))}
        </ul>
      </div>

      <div className="column">
        <h3>SISTEMA</h3>
        <ul>
          {systemFolders.map(folder => (
            <FolderItem key={folder.id} file={folder} onClick={handleSystemFolderClick} icon={<FaCogs className="sistema-icon" />} />
          ))}
        </ul>
      </div>

      <div className="column">
        <h3>{currentFolderName}</h3>
        {shouldShowSearchInput && (
          <SearchBar
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            handleSearch={handleSearch}
          />
        )}
        {loading ? (
          <LoadingSpinner />
        ) : error ? (
          <div>Error: {error}</div>
        ) : files.length > 0 ? (
          <ul>
            {files.map(file => (
              <FileItem key={file.id} file={file} />
            ))}
          </ul>
        ) : (
          <div></div>
        )}
      </div>
    </div>
    </div>
  );
};

export default App;
