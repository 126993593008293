import React from 'react';
import { FaHome, FaSearch, FaBell, FaUserCircle } from 'react-icons/fa';
import './Header.css'; // Importando o CSS específico para o Header

const Header = () => {
  return (
    <header className="header">
      <div className="logo-container">
        <img src="https://appclouds.com.br/autochip/images/logo-marca-auto-doc.png" alt="Logo" className="logo" />
      </div>
    </header>
  );
};

export default Header;
